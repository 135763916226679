import React, { Component } from 'react';
import { Link } from 'gatsby';
import { Menu } from 'semantic-ui-react';

const Navigation = () => (
    <Menu fluid vertical>
        <Menu.Item as={Link} to="/about/" name="about" content="About Us" activeClassName="active" />
        <Menu.Item as={Link} to="/contact/" name="contact" content="Contact Us" activeClassName="active" />
        <Menu.Item
            as={Link}
            to="/privacy-policy/"
            name="privacy-policy"
            content="Privacy Policy"
            activeClassName="active"
        />
        <Menu.Item as={Link} to="/terms-of-use/" name="terms-of-use" content="Terms of Use" activeClassName="active" />
        <Menu.Item as={Link} to="/email-opt-out/" name="email-opt-out" content="Unsubscribe" activeClassName="active" />
    </Menu>
);

export default Navigation;
