import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Header, List } from 'semantic-ui-react';
import Layout from '../components/layout';
import Helmet from '../components/Helmet';
import * as DefaultTemplate from '../components/ContentPages/DefaultTemplate';

const SitemapPage = ({
    location,
    data,
}) => {
    const allWordpressPost = (data.allWordpressPost && data.allWordpressPost.edges
        && data.allWordpressPost.edges
            .filter(({ node }) => node.acf.robots === null || node.acf.robots && !node.acf.robots.includes('noindex'))
            .map(({ node }) => (node))) || [];
    const allSitePages = (data.allSitePage && data.allSitePage.edges && data.allSitePage.edges.map(({ node }) => (node))) || [];
    const siteMapData = [];
    const uniqueHrefs = [];
    allWordpressPost.sort((a, b) => a.slug < b.slug ? -1 : 1).forEach((post) => {
        siteMapData.push({href: post.slug, title: post.title});
    });
    const links = siteMapData.sort((a, b) => (a.title < b.title ? -1 : 1)).map((entry) => {
        if (entry.href.length === 0 || uniqueHrefs.includes(entry.href)) {
            return false;
        }

        uniqueHrefs.push(entry.href);
        return (
            <List.Item
                key={entry.href}
                as="a"
            >
                <List.Content>
                    <a data-sitemap-url href={entry.href} title={entry.title}>{entry.title}</a>
                </List.Content>
            </List.Item>
        );
    });
    return (
        <Layout
            showDisclosures={false}
        >
            <Helmet
                title="Sitemap"
                description="The sitemap for DebtConsolidation.com."
                robots="noindex, follow"
            />

            <DefaultTemplate.Wrapper>
                <DefaultTemplate.Body>
                    <Header as="h1" size="large">
                        Sitemap
                    </Header>
                    <List
                        bulleted
                        relaxed
                    >
                        {links}
                    </List>
                </DefaultTemplate.Body>
            </DefaultTemplate.Wrapper>
        </Layout>
    );
};


SitemapPage.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default SitemapPage;

export const query = graphql`
    {
        allSitePage {
            edges {
                node {
                    path
                }
            }
        }
        allWordpressPost(
            filter: {
                discoverable: {
                    eq: true
                }
            }
            sort: {fields: [title], order: ASC}
        ) {
            edges {
                node {
                    id
                    wordpress_id
                    title
                    slug
                    categories {
                        slug
                    }
                    protected
                    discoverable
                    excerpt
                    truncated_excerpt
                    large_image {
                        width
                        height
                        url
                    }
                    acf {
                        seo_title
                        robots
                    }
                }
            }
        }
    }
`;
