import React from 'react';
import PropTypes from 'prop-types';
import {
    Container, Divider, Grid,
} from 'semantic-ui-react';
import Navigation from './Navigation';

const Body = props => props.children;

Body.displayName = 'Body';

const Wrapper = (props) => {
    const { children } = props;
    const sections = {
        body: React.Children.toArray(children).filter(child => child.type.displayName === 'Body'),
    };

    return (
        <Container>
            <Divider hidden />
            <Grid stackable columns={2}>
                <Grid.Row>
                    <Grid.Column width={11}>
                        {sections.body}
                    </Grid.Column>
                    <Grid.Column width={5}>
                        <Navigation />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
};

Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
};

export {
    Wrapper, Body,
};
